<template>
  <div class="brand-item">
    <locale-router-link :to="`/brands/${item.id}`">
      <img
        class="brand-item__image"
        :src="getImageUrl"
        alt=""
      />
    </locale-router-link>
  </div>
</template>

<script>
export default {
  name: "AppBrandItem",

  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    getImageUrl() {
      if (this.item.attributes?.image?.data) {
        return this.$helpers.getAbsolutePath(this.item.attributes?.image?.data?.attributes?.url)
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
/* .big {

  &__image {
    height: 290px;
  }

  @media screen and (max-width: 992px) {
    width: 48%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
} */
</style>
