<template>
  <div class="brands">
    <div class="container">
      <div class="brands__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link page-catalog-link"> {{ $t("brands.title") }}</a>
      </div>

      <div class="brands__main">
        <h1 class="brands__title">{{ $t("brands.title") }}</h1>
        <p class="brands__text">{{ $t("brands.text") }}</p>
      </div>

      <div class="brands__catalog">
        <app-brand-item
          :key="item.id"
          v-for="(item, index) in brands"
          :item="item"
          :class="{ big: checkItemSize(index) }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllBrands } from "@/api/brands"

import AppBrandItem from "@/components/brands/AppBrandItem.vue"

export default {
  name: "PageBrands",

  components: {
    AppBrandItem,
  },

  data() {
    return {
      brands: [],
    }
  },

  mounted() {
    this.loadAllBrands()
  },

  methods: {
    async loadAllBrands() {
      const response = await getAllBrands()
      this.brands = response.data
    },
    checkItemSize(e) {
      const index = e + 1
      const side = Math.ceil(index / 2) % 2 === 1 ? "left" : "right"
      return (side === "left" && index % 2 === 1) || (side === "right" && index % 2 === 0)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
